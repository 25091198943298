import { API_URL } from '../../AppConstants';
import * as React from 'react'
import { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Chart from "react-apexcharts";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { connect } from 'react-redux';

const Histogram = ({dispatch, }) => {

    const [chartType, setChartType] = useState('sessions')
    const [timespan, setTimespan] = useState('hour')
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 86400000), new Date()])
    const [dataSeries, setDataSeries] = useState([])
    const [chartOptions, setChartOptions] = useState({})
    const [uniqueTotal, setUniqueTotal] = useState(false)

    useEffect(() => {
      if(dateRange) {
        let startDate = formatDate(dateRange[0])
        let endDate = formatDate(dateRange[1])
        if(timespan === 'hour' && chartType !== 'sessions') {
          setTimespan('day')
        } else {
           fetch(`${API_URL}/logs/histogram_stats?timespan=${timespan}&startDate=${startDate}&endDate=${endDate}&chartType=${chartType}`).then(resp => resp.json()).then(response => {
            console.log(response)
            setChart(response.data)
            setUniqueTotal(response.other_total)
          })
        }
      }
    }, [dateRange, chartType, timespan])

    const setChart = (data) => {

      let series = []
      data.forEach(dataSet => {
        series.push({
          name: dataSet.label,
          data: dataSet.values.map((row) => {
            return {
              x: row.date,
              y: row.dataPoint
            }
          })
        })
      })
      setDataSeries(series)

      const options = {
        chart: {
          type: "bar",
          stacked: true,
        },
        plotOptions: {
          bar: {
            columnWidth: "95%",
            strokeWidth: 2,
            borderRadius: 5,
            borderRadiusApplication: "end"
          }
        },
        fill: {
          colors: data.map(dataSet => dataSet.color),
          opacity: 0.3
        },
        stroke: {
          width: 1,
          colors: ["#333"]
        },
        dataLabels: { enabled: false },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          type: "numeric",
          min: 0,
          // max: yData.length,
          // tickAmount: yData.length,
          labels: { formatter: (x) => x /*Math.round(x)*/ },
          // title: { text: "Hours", offsetY: 70 },
          axisBorder: {
            color: "#000000"
          }
        },
        yaxis: {
          // title: { text: "Sessions" },
          min: 0,
          forceNiceScale: true,
          decimalsInFloat: 1,
          // max: Math.max(...yData),
          axisBorder: {
            show: true,
            color: "#000000"
          }
        },
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: true
          },
          x: {
            formatter: (x) => {
              return x;
            }
          },
          y : {
            formatter: (y) => {
              return y + (chartType === 'never_returning_users' ? '%' : '')
            }
          }
        }
      };

      setChartOptions(options)
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    let totalData = 0;
    dataSeries.forEach(dataset => {
      totalData = totalData + dataset.data.reduce((a, b) => a + b.y, 0)
    })

    return (
        <div style={{height:'100%', width: '100%'}}>
          <div style={{margin: 30}}>
            <div style={{float:'right', display:"flex"}}>
              <div style={{marginRight: 15}}>
                <select onChange={(e) => setChartType(e.target.value)}>
                  <option value="sessions">Sessions</option>
                  <option value="unique_user_logins">Unique User Logins</option>
                  <option value="new_users">New Users</option>
                  <option value="returning_users">Returning Users</option>
                  <option value="average_time_used">Avg Time Used</option>
                  <option value="total_time_used">Total Time Used</option>
                  <option value="trips_started">Trips Started</option>
                  <option value="trips_completed">Trips Completed</option>
                  <option value="time_to_park">Time to Park</option>
                  <option value="never_returning_users">Never Returning Users</option>
                </select>
              </div>
              <div style={{marginRight: 15}}>
                <select onChange={(e) => setTimespan(e.target.value)}>
                  {chartType === 'sessions' ?
                    <option value="hour">Hour</option>
                  : false}
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
              <div>
                <DateRangePicker value={dateRange} onChange={setDateRange} disabled={loading} />
              </div>
            </div>
            <h2 style={{color: 'white',fontWeight: 'bold', fontSize:"2vw", textTransform: "capitalize"}}>
              Total {chartType.replace(/_/g, ' ')} : {chartType === 'never_returning_users' ? uniqueTotal : totalData} {chartType === 'unique_user_logins' ? `(${uniqueTotal} Unique Users)` : false}
            </h2>
          </div>
        <Chart options={chartOptions} series={dataSeries} type="bar" width={'90%'} height={'90%'} />
      </div>
    )
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(Histogram);
