import { combineReducers } from "redux";
import users from "./reducer_users";
import logs from "./reducer_logs";
import adminUser from "./reducer_admin";

const rootReducer = combineReducers({
    users,
    logs,
    adminUser
});

export default rootReducer;
