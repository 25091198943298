import { API_URL } from '../AppConstants';
import { setAdminUserLoggedOut } from './actions_admin_users';
export const SET_USERS = "SET_USERS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_SORT_BY = "SET_SORT_BY";
export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const SET_PASSWORD_VALIDATION_STATE = "SET_PASSWORD_VALIDATION_STATE";
export const SET_PASSWORD_RESPONSE = "SET_PASSWORD_RESPONSE";

export function getUsers(token) {

  return (dispatch) => {
    // Sends email, password, total currency to start
    return fetch(`${API_URL}/users`,{
      headers: {
        authorization: "Bearer: " + token
      }
    },).then(resp => resp.json()).then(response => {
      if(response.msg && response.msg.includes("Error")){
        console.log('failed')
        dispatch(setAdminUserLoggedOut())
      }
      else {
        dispatch(setUsers(response))
      }
    });
  };
}

export function validatePasswordResetToken(token) {
  return (dispatch) => {
    // Sends email, password, total currency to start
    return fetch(`${API_URL}/users/validate/reset_password?token=${token}`).then(resp => resp.json()).then(response => {
      dispatch(setPasswordValidation(response.email, response.msg))
    });
  };
}

export function updatePassword(password, token) {
  return (dispatch) => {
    // Sends email, password, total currency to start
    return fetch(`${API_URL}/users/update_password`,{
      method: 'POST',
      body: JSON.stringify({password, token}),
      headers: {
          'Content-Type': 'application/json'
      }
  }).then(resp => resp.json()).then(response => {
      dispatch(setPasswordResponse(response.msg))

    });
  };
}

export function getUserStats(token, userID) {
  return (dispatch) => {
    // Sends email, password, total currency to start
    return fetch(`${API_URL}/users/user_stats?userID=` + userID,{
      headers: {
        authorization: "Bearer: " + token
      }
    },).then(resp => resp.json()).then(response => {
      console.log(response)
      if(response.msg && response.msg.includes("Error")){
        console.log('failed')
        dispatch(setAdminUserLoggedOut())
      }
      else {
        dispatch(setUserData(response.userData))
      }
    });
  };
}

export function setUsers(users) {
  return {
    type: SET_USERS,
    payload: users,
  };
}

export function setUserData(userData) {
  return {
    type: SET_USER_DATA,
    payload: userData,
  };
}

export function setSortOrder(sortOrder) {
  return {
    type: SET_SORT_ORDER,
    payload: sortOrder,
  };
}

export function setSortBy(sortBy) {
  return {
    type: SET_SORT_BY,
    payload: sortBy,
  };
}

export function setPasswordValidation(email, msg) {
  return {
    type: SET_PASSWORD_VALIDATION_STATE,
    payload: {email, msg},
  };
}

export function setPasswordResponse(msg) {
  return {
    type: SET_PASSWORD_RESPONSE,
    payload: {msg},
  };
}
