import { ArrowUp, ArrowDown, PersonFill } from 'react-bootstrap-icons';

export const PeakCongestion = ({parkingLotThroughoutDay}) => {

    let startOfPeak = false;
    let endOfPeak = false;
    if(parkingLotThroughoutDay.length > 0) {
      startOfPeak = false;
      endOfPeak = false;
      parkingLotThroughoutDay.forEach(dataSegment => {
        if(dataSegment.pct > 0.9 && !startOfPeak) {
          startOfPeak = dataSegment.datetime;
        }
        if(dataSegment.pct < 0.9 && startOfPeak && !endOfPeak) {
          endOfPeak = dataSegment.datetime;
        }
      })
    }

    const formatTime = (datetime) => {
      const dataDate = new Date(datetime); //this changes depending on your timezone
      const dataMilliSeconds = dataDate.getTime()
      const dataUTCTime = dataDate.toUTCString().split(' ');
      const dataHour = parseInt(dataUTCTime[4].split(':')[0]);
      let dataMinute = parseInt(dataUTCTime[4].split(':')[1]);
      if(dataMinute === 0) {
        dataMinute = '00';
      }
      return dataHour + ":" + dataMinute;
    }

    return (
        <div style={{background:"rgb(51, 64, 92)" ,width:'18%', height:'120px', borderRadius:"0px 0px 8% 8%", color:"white", padding:'0.1% 2% 1% 2%'}}>
            <>
                <div style={{height:"60px"}}>
                    <h2 style={{fontSize:'1.8vw', lineHeight:'normal', fontWeight:"bold", color:'orange'}}>{startOfPeak ? formatTime(startOfPeak) : '--:--'}</h2>
                    <div style={{fontSize:'.5vw', fontWeight:"800", color:'lightgrey',  lineHeight:'normal'}}>TO</div>
                </div>

                <div style={{height:'60px', display:'flex', flexDirection:'column'}}>
                    <div style={{height:"60px"}}>
                        <h2 style={{fontSize:'1.8vw', lineHeight:'normal', fontWeight:"bold", color:'limegreen'}}>{endOfPeak ? formatTime(endOfPeak) : '--:--'}</h2>
                        <div style={{fontSize:'.5vw', fontWeight:"800", color:'lightgrey',  lineHeight:'normal'}}>AVERAGE PEAK TIME</div>
                    </div>
                </div>

            </>
        </div>
    )
}
