import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {  ArrowLeftShort, Check2Circle, Display, PersonFill } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getUserStats } from "../../actions/actions_users";
const options = {
    year: 'numeric', month: 'numeric', day: 'numeric',

    hour12: false
}
const UserCard = ({users, dispatch, token}) => {
    const params = useParams()
    const history = useHistory()
    const [ currentUser, setCurrentUser ] = useState(false)

    useEffect(() => {
      dispatch(getUserStats(token, params.userID))
    }, [])

    useEffect(() => {
        console.log("users", users)
        const user = users.find(user => user.id === parseInt(params.userID))
        setCurrentUser(user)
    }, [users])

    const createTimeStamp = (created_at) => {
        if(created_at){
            const time = new Intl.DateTimeFormat('en-US', options).format(Date.parse(created_at));
            return time
        }
        else {
            return "NA"
        }
    }

    return (
        <div>
            <header className="user_container">
                <div className="back_button" onClick={()=> history.goBack()}><ArrowLeftShort size={30} fontWeight={700}></ArrowLeftShort></div>
                <h1 style={{margin:0}}>User Profile</h1>
            </header>
            {
                currentUser ?
                    <Container>
                        <Row>
                            <Col><h3>Basic Info</h3></Col>
                            <Col><h3>Statistics</h3></Col>
                            <Col xs={6}><h3>Notes</h3></Col>
                        </Row>
                        <Row className="basic_info_container">
                            <Col>
                                <section style={{display:'flex', alignItems:'flex-end', }}>
                                        <div className="profile_placeholder">
                                            <PersonFill height={80} width={80}></PersonFill>
                                        </div>
                                       <div style={{paddingLeft:'5px'}}>
                                            <div style={{display:'flex'}}>
                                                <h3 className="basic_info_name">{currentUser.first_name} {currentUser.last_name}</h3>
                                                <div style={{backgroundColor: currentUser.permit ? currentUser.permit.color : "", marginLeft:'5px'}} className='permit_letter'>{currentUser.permit ? currentUser.permit.letter : ""}</div>
                                            </div>
                                            <div  style={{display:'flex'}}>
                                                <p style={{color:"white", fontWeight:'bold'}}>User Since:</p>
                                                <p style={{fontWeight:'bold', marginLeft: '5px'}}>{createTimeStamp(currentUser.created_at)}</p>
                                            </div>
                                        </div>
                                </section>
                                <section style={{display:'flex', marginTop:"30px", alignItems: 'center', flexDirection:'column'}}>
                                    <div className="basic_info_item_box">
                                        <div style={{display:'flex'}} >
                                            <p className="white_text">Email:</p>
                                            <p className="black_text" style={{marginLeft: '5px'}}>{currentUser.email}</p>

                                        </div>
                                        <Check2Circle color="rgb(152, 181, 242)"></Check2Circle>
                                    </div>


                                    <div className="last_login_container">
                                        <p className="white_text">Last login:</p>
                                        <p className="black_text" style={{marginLeft: '5px'}}> {createTimeStamp(currentUser.last_login_at)}</p>
                                    </div>



                                    <div className="change_button">
                                        Change
                                    </div>
                                </section>
                            </Col>

                            <Col style={{paddingTop:"15px"}}>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Total Duration:</p>
                                    <p className="black_text bold_text"> {Math.round(currentUser.totalTimeSpent/60)} min</p>
                                </div>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Average Duration:</p>
                                    <p className="black_text bold_text"> {Math.round((currentUser.totalTimeSpent/60)/currentUser.sessions)} min</p>
                                </div>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Total Sessions:</p>
                                    <p className="black_text bold_text"> {currentUser.sessions}</p>
                                </div>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Trips Started:</p>
                                    <p className="black_text bold_text"> {currentUser.tripsStarted}</p>
                                </div>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Trips Completed:</p>
                                    <p className="black_text bold_text"> {currentUser.tripsCompleted}</p>
                                </div>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Miles Driven:</p>
                                    <p className="black_text bold_text"> {currentUser.totalMiles}</p>
                                </div>

                                <div className="stat_item">
                                    <p className="white_text bold_text" style={{marginRight: 10}}>Average Time to Park:</p>
                                    <p className="black_text bold_text"> {Math.round(currentUser.totalTimeToPark/currentUser.tripsCompleted)}</p>
                                </div>

                            </Col>

                            <Col xs={6}>

                            </Col>
                        </Row>
                    </Container>
                    :
                    <h3>No info</h3>
            }
        </div>

    )
}

const mapStateToProps = (state) => ({
    users: state.users.users,
    token: state.adminUser.token,
    total_count: state.users.total_count
});

export default connect(mapStateToProps)(UserCard);
