import AnchorLink from 'react-anchor-link-smooth-scroll'
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";

function Nav({requestAdminAccess}) {
  return (
    <Router>
      <nav className="header__nav">
        {/* <a className="icon-nav-mobile js-toggle-nav d-block d-md-none" href="javascript:void(0)"><span></span></a> */}
        <ul className="headerMenu d-flex justify-content-around">
          <li className="headerMenu__item"><AnchorLink href="#technology" className="js-anchor">technology</AnchorLink></li>
          <li className="headerMenu__item"><AnchorLink href="#how-it-works" className="js-anchor">how it works</AnchorLink></li>
          <li className="headerMenu__item"><AnchorLink href="#solutions" className="js-anchor">Solutions</AnchorLink></li>
          <li className="headerMenu__item"><AnchorLink href="#about" className="js-anchor">About</AnchorLink></li>
          <li className="headerMenu__item"><AnchorLink href="#news" className="js-anchor">News</AnchorLink></li>
          <li className="headerMenu__item"><Link to="/admin/stat" onClick={() => requestAdminAccess()} className="js-anchor">Admin</Link></li>
        </ul>
      </nav>
      
    </Router>
  );
}

export default Nav;
