import { useEffect, useState } from 'react';
import { Alert, Button, Card, Form, FormControl, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { updatePassword, validatePasswordResetToken } from '../actions/actions_users';
import Nav from './Nav';

function ResetPassword({ setCurrentSection, currentSection, requestAdminAccess, dispatch, validate_password_reset_msg, reset_password_email, password_reset_response_msg }) {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        validateToken()
    }, []);

    useEffect(()=>{
        setError("");
    }, [password, passwordConfirm])

    useEffect(() => {
        console.log(validate_password_reset_msg)
    }, [validate_password_reset_msg]);

    const validateToken = () => {
        dispatch(validatePasswordResetToken(token))
    }

    const submitNewPassword = (e) => {
        e.preventDefault()
        if(password.length < 6){
            setError("Password has to be 6 characters or more.")
        }
        else if(password !== passwordConfirm){
            setError("Passwords don't match.")
        }
        else {
            dispatch(updatePassword(password, token))
        }
    }
  return (
    <div>
      <h1 className="title-seo">Nine20 | HOMEsdfsd</h1>
      <div id="wrapper">

        <header className="header">
          <div className="header__top">
            <div className="d-flex justify-content-center align-items-center">
              <div className="header__logo">
                <a href="/"><img src="/images/public/Logo@2x.png" alt="920" /></a>
              </div>
            </div>

          </div>
        </header>
        <main>
            <div style={{display: 'flex',alignItems: 'center', width: '100%', justifyContent: 'center', marginTop: '40px'}}>
               {!validate_password_reset_msg ? password_reset_response_msg ?
                <h1>{password_reset_response_msg}</h1>
               :
               <Card style={{padding: 20}}>
                    <Card.Title>Reset password for {reset_password_email}.</Card.Title>
                    <Form>
                        {error && <Alert variant={"secondary"}>
                            {error}
                        </Alert>}

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control onChange={(e) => setPasswordConfirm(e.target.value)} type="password" placeholder="Confirm Password" />
                        </Form.Group>

                        <Button onClick={submitNewPassword} variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>

                </Card>:
                <h1>{validate_password_reset_msg}</h1>
                }

            </div>
        </main>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
    validate_password_reset_msg: state.users.validate_password_reset_msg,
    reset_password_email: state.users.reset_password_email,
    password_reset_response_msg: state.users.password_reset_response_msg
});

export default connect(mapStateToProps)(ResetPassword);

