import { SET_CUMULATIVE_TIME, SET_FIRST_APP_OPEN_COUNT, SET_PARKING_LOT_OCCUPATION, SET_STATS } from "../actions/actions_logs";

const initialState = {
  firstAppOpenCount: 0,
  cumulativeTime: {date: "", cumTimeSecs: 0, deviceCount: 0, avg: 0},
  parkingLotThroughoutDay: [],
  totalSessions: false,
  usersThisDay : false,
  newUsers: false,
  totalMinutes: false,
  averageMinDuration: false,
  tripsStarted : false,
  tripsCompleted : false,
  totalMiles : false,
  totalTimeToPark : false,
  totalSessionsPrev: false,
  newUsersPrev: false,
  totalMinutesPrev: false,
  averageMinDurationPrev: false,
  tripsStartedPrev: false,
  tripsCompletedPrev: false,
  totalMilesPrev: false,
  totalTimeToParkPrev: false,
};

const logs = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FIRST_APP_OPEN_COUNT:
      return {
        ...state,
        firstAppOpenCount : payload.count
      }
    case SET_CUMULATIVE_TIME:
      return {
        ...state,
        cumulativeTime : payload
      }
    case SET_PARKING_LOT_OCCUPATION:
      return {
        ...state,
        parkingLotThroughoutDay : payload.data
      }
    case SET_STATS:
      return {
        ...state,
        totalSessions: payload.sessions,
        totalSessionsPrev : payload.sessionsPrev,
        newUsers : payload.newUsers,
        usersThisDay : payload.usersThisDay,
        newUsersPrev : payload.newUsersPrev,
        totalMinutes : payload.totalMinutes,
        totalMinutesPrev : payload.totalMinutesPrev,
        averageMinDuration : payload.averageMinDuration,
        averageMinDurationPrev : payload.averageMinDurationPrev,
        tripsStarted : payload.tripsStarted,
        tripsStartedPrev : payload.tripsStartedPrev,
        tripsCompleted : payload.tripsCompleted,
        tripsCompletedPrev : payload.tripsCompletedPrev,
        totalMiles : payload.totalMiles,
        totalMilesPrev : payload.totalMilesPrev,
        totalTimeToPark : payload.totalTimeToPark,
        totalTimeToParkPrev : payload.totalTimeToParkPrev
      }
    default:
      return state;
  }
};

export default logs;
