import * as React from 'react'

export default function Feedback(){
    return (
        <div style={{padding:'25px'}}>
            <h1>Feedback</h1>
            <p style={{color: "white", marginTop: 15}}>Feedback is currently email based and not yet implemented on the Admin Portal.</p>
        </div>
    )
}
