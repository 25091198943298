import { SET_PASSWORD_RESPONSE, SET_PASSWORD_VALIDATION_STATE, SET_SORT_ORDER, SET_SORT_BY, SET_USERS, SET_USER_DATA } from "../actions/actions_users";

const initialState = {
  users: [],
  validate_password_reset_msg: false,
  reset_password_email: false,
  sortBy : false,
  sortOrder : 'asc',
  password_reset_response_msg: false
};

const users = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USERS:
      return {
        ...state,
        users: payload.users
      }
    case SET_SORT_BY:
      console.log(payload)
      return {
        ...state,
        sortBy: payload
      }
    case SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload
      }
    case SET_USER_DATA:
      let newUsersArray = JSON.parse(JSON.stringify(state.users));
      const userIndex = newUsersArray.findIndex(user => user.id === payload.id)
      newUsersArray[userIndex] = payload
      return {
        ...state,
        users: newUsersArray
      }
    case SET_PASSWORD_VALIDATION_STATE:
      return {
        ...state,
        validate_password_reset_msg: payload.msg,
        reset_password_email: payload.email
      }
    case SET_PASSWORD_RESPONSE:
      return {
        ...state,
        password_reset_response_msg: payload.msg
      }
    default:
      return state;
  }
};

export default users;
