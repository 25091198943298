import { useState, useEffect } from 'react';
import PublicPage from './PublicPage';
import AdminPage from './AdminPage';
import { Button, Modal, InputGroup, FormControl } from 'react-bootstrap';

import ResetPassword from './ResetPassword';
import { loginAdmin, setAdminUserLoggedIn } from '../actions/actions_admin_users';
import { connect } from 'react-redux';


function App({dispatch, admin_user, token}) {

  const [currentSection, setCurrentSection] = useState('public')
  const [showLogin, setShowLogin] = useState(false);
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const handleClose = () => setShowLogin(false);
  const handleShow = () => setShowLogin(true);

  useEffect(() => {
    if(window.location.href.includes("/admin")){
      requestAdminAccess()
    }
    else if(window.location.href.includes("/reset-password") && window.location.href.includes("token")){
      setCurrentSection("reset-password")
    }
  }, []);

  const requestAdminAccess = () => {
    // setCurrentSection
    if(localStorage.getItem('token') !== null){
      dispatch(setAdminUserLoggedIn({}, localStorage.getItem('token') ))
      setCurrentSection('admin')
      handleClose()

    }
    else {
      handleShow()
    }
  }

  useEffect(()=>{
    if(admin_user){
      handleClose()
      setCurrentSection('admin')
    }
  }, [admin_user])

  const loginButton = ()=>{
    // setCurrentSection('admin')
    // if(email === 'nine920' && password === 'test') {
    //   setCurrentSection('admin')
    // }
    if(email && password) {
      dispatch(loginAdmin(email, password))
    }
  }

  const closeButton = ()=>{
    handleClose()
    window.history.pushState({}, null, '/')
  }

  return (
      <div>
          <Modal show={showLogin} >
            <Modal.Header >
              <Modal.Title>Admin Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h2>Email</h2>
              <InputGroup size="sm" className="mb-3">
                <FormControl aria-label="Small" onChange={e => setEmail(e.currentTarget.value)} aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
              <h2>Password</h2>
              <InputGroup size="sm" className="mb-3">
                <FormControl type={'password'} onChange={e => setPassword(e.currentTarget.value)} aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
              </InputGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeButton} variant="secondary">
                Close
              </Button>
              <Button style={{backgroundColor:"#4e5f8f"}}  onClick={loginButton} >
                Login
              </Button>
            </Modal.Footer>
          </Modal>
        {currentSection === 'public' ? <PublicPage currentSection={currentSection} requestAdminAccess={requestAdminAccess} /> : false}
        {currentSection === 'admin' ? <AdminPage currentSection={currentSection} setCurrentSection={setCurrentSection} /> : false}
        {currentSection === 'reset-password' ? <ResetPassword currentSection={currentSection} setCurrentSection={setCurrentSection} /> : false}
      </div>
  );
}


const mapStateToProps = (state) => ({
  admin_user: state.adminUser.user,
  token: state.adminUser.token,

});

export default connect(mapStateToProps)(App);
