import Nav from './Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import Map from './adminPages/Map';
import Stat from './adminPages/Stat';
import Histogram from './adminPages/Histogram';
import Users from './adminPages/Users';
import Feedback from './adminPages/Feedback';
import { connect } from 'react-redux';
import { setAdminUserLoggedOut } from '../actions/actions_admin_users';
import { HouseDoorFill, PeopleFill, ChatLeftFill, BarChartFill } from 'react-bootstrap-icons';
import { useEffect, useRef } from 'react';
import UserCard from './adminPages/UserCard';
import { getUsers } from '../actions/actions_users';

function AdminPage({ setCurrentSection, currentSection, dispatch, token }) {
  const signOut = () => {
    dispatch(setAdminUserLoggedOut())
    window.location.reload();
  }
  useEffect(() => {
    console.log('here')
    dispatch(getUsers(token))
}, []);



  return (
    <Router>
      <div>
        <h1 className="title-seo">Nine20 | HOME</h1>
        <div id="wrapper">
          <header className="header admin_header">
            <div className="header__top">
              <div className="d-flex justify-content-between align-items-center">
                <div className="header__logo">
                  <a href="/"><img src="/images/public/Logo@2x.png" alt="920" /></a>
                </div>

                <nav className="header__nav">
                  <ul className="headerMenu d-flex justify-content-around">
                    <li className="headerMenu__item"><Link to="/admin/stat" className="js-anchor">Stats</Link></li>
                    <li className="headerMenu__item"><Link to="/admin/users" className="js-anchor">Users</Link></li>
                    <li className="headerMenu__item"><Link to="/admin/histogram" className="js-anchor">Histogram</Link></li>
                    <li className="headerMenu__item"><Link to="/admin/feedback" className="js-anchor">Feedback</Link></li>
                    <li className="headerMenu__item"><a onClick={signOut} className="js-anchor">Sign out</a></li>
                  </ul>
                </nav>

              </div>
            </div>
          </header>
          <div className='admin_content'>
            <div className='card_wrapper'>
              <div className='card_sidenav'>
                <ul>
                  <Switch>
                    <Route path="/admin/stat">
                      <Link to="/admin/stat" className="js-anchor"><li className={ "headerMenu__item selected"}><HouseDoorFill size="20px" color='white'></HouseDoorFill></li></Link>
                      <Link to="/admin/users" className="js-anchor"><li className={ "headerMenu__item"}><PeopleFill size="20px" color='white'></PeopleFill></li></Link>
                      <Link to="/admin/histogram" className="js-anchor"><li className={ "headerMenu__item"}><BarChartFill size="20px" color='white'></BarChartFill></li></Link>
                      <Link to="/admin/feedback" className="js-anchor"><li className={ "headerMenu__item"}><ChatLeftFill size="20px" color='white'></ChatLeftFill></li></Link>

                    </Route>
                    <Route path="/admin/users">
                      <Link to="/admin/stat" className="js-anchor"><li className={ "headerMenu__item"}><HouseDoorFill size="20px" color='white'></HouseDoorFill></li></Link>
                      <Link to="/admin/users" className="js-anchor"><li className={ "headerMenu__item selected"}><PeopleFill size="20px" color='white'></PeopleFill></li></Link>
                      <Link to="/admin/histogram" className="js-anchor"><li className={ "headerMenu__item"}><BarChartFill size="20px" color='white'></BarChartFill></li></Link>
                      <Link to="/admin/feedback" className="js-anchor"><li className={ "headerMenu__item"}><ChatLeftFill size="20px" color='white'></ChatLeftFill></li></Link>

                    </Route>
                    <Route path="/admin/feedback">
                      <Link to="/admin/stat" className="js-anchor"><li className={ "headerMenu__item"}><HouseDoorFill size="20px" color='white'></HouseDoorFill></li></Link>
                      <Link to="/admin/users" className="js-anchor"><li className={ "headerMenu__item"}><PeopleFill size="20px" color='white'></PeopleFill></li></Link>
                      <Link to="/admin/histogram" className="js-anchor"><li className={ "headerMenu__item"}><BarChartFill size="20px" color='white'></BarChartFill></li></Link>
                      <Link to="/admin/feedback" className="js-anchor"><li className={ "headerMenu__item selected"}><ChatLeftFill size="20px" color='white'></ChatLeftFill></li></Link>

                    </Route>
                     <Route path="/admin/user">
                      <Link to="/admin/stat" className="js-anchor"><li className={ "headerMenu__item"}><HouseDoorFill size="20px" color='white'></HouseDoorFill></li></Link>
                      <Link to="/admin/users" className="js-anchor"><li className={ "headerMenu__item selected"}><PeopleFill size="20px" color='white'></PeopleFill></li></Link>
                      <Link to="/admin/histogram" className="js-anchor"><li className={ "headerMenu__item"}><BarChartFill size="20px" color='white'></BarChartFill></li></Link>
                      <Link to="/admin/feedback" className="js-anchor"><li className={ "headerMenu__item"}><ChatLeftFill size="20px" color='white'></ChatLeftFill></li></Link>

                    </Route>
                     <Route path="/admin/histogram">
                      <Link to="/admin/stat" className="js-anchor"><li className={ "headerMenu__item"}><HouseDoorFill size="20px" color='white'></HouseDoorFill></li></Link>
                      <Link to="/admin/users" className="js-anchor"><li className={ "headerMenu__item"}><PeopleFill size="20px" color='white'></PeopleFill></li></Link>
                      <Link to="/admin/histogram" className="js-anchor"><li className={ "headerMenu__item selected"}><BarChartFill size="20px" color='white'></BarChartFill></li></Link>
                      <Link to="/admin/feedback" className="js-anchor"><li className={ "headerMenu__item"}><ChatLeftFill size="20px" color='white'></ChatLeftFill></li></Link>

                    </Route>
                  </Switch>

                </ul>
              </div>
              <div className='card_content'>
              <Switch>
                <Route path="/admin/stat">
                  <Stat></Stat>
                </Route>
                <Route path="/admin/histogram">
                  <Histogram></Histogram>
                </Route>
                <Route path="/admin/users">
                  <Users></Users>
                </Route>
                <Route path="/admin/user/:userID">
                  <UserCard></UserCard>
                </Route>
                <Route path="/admin/feedback">
                  <Feedback></Feedback>
                </Route>
              </Switch>

              </div>

            </div>

          </div>

        </div>
      </div>
    </Router>
  );
}


const mapStateToProps = (state) => ({
  token: state.adminUser.token,

});

export default connect(mapStateToProps)(AdminPage);
