import * as React from 'react'
import { useState, useEffect } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Chart from "react-apexcharts";
import DatePicker from 'react-date-picker';
import { connect } from 'react-redux';
import { getCumulativeTime, getDataForPastWeek, getFirstAppOpenCount, getParkingLotOccupation, getStatsForDate } from '../../actions/actions_logs';
import { TotalSessions } from '../widgets/TotalSessions';
import { MedWidget } from '../widgets/MedWidget';
import { SmallWidget } from '../widgets/SmallWidget';
import { PeakCongestion } from '../widgets/PeakCongestion';
import { Clock, GraphUpArrow, PersonFill, ArrowRight, ArrowLeft } from 'react-bootstrap-icons';


const Stat = ({dispatch, firstAppOpenCount, cumulativeTime, parkingLotThroughoutDay, totalSessions, newUsers, totalMinutes, averageMinDuration, usersThisDay, tripsStarted, tripsCompleted, totalTimeToPark, totalMiles, totalSessionsPrev, newUsersPrev, averageMinDurationPrev, totalMinutesPrev, tripsStartedPrev, tripsCompletedPrev, totalTimeToParkPrev, totalMilesPrev}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [timeForCalendar, setTimeForCalendar] = useState(new Date());
    const [loading, setLoading] = useState(true)

    const [options, setOptions] = useState({
        chart: {
            id: "basic-bar",
            animations : {
              enabled : false
            }
        },
        xaxis: {
            categories: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        yaxis: {
            max: 100,
            min: 0,
            labels: {
                show: true,
                formatter: (value) => { return value + "%" }
            }
        }
    });
    const [series, setSeries] = useState([
        {
            name: "% of Spots Taken",
            data: [1,2,3,4,5,6,7,8,9,10]
        }
    ]);

    useEffect(() => {
        // dispatch(getFirstAppOpenCount())
    }, []);

    useEffect(() => {
        const timeString = formatDateTimeYYYMMDD(timeForCalendar)
        setLoading(true)
        dispatch(getStatsForDate(timeForCalendar))

        // dispatch(getCumulativeTime(timeString))
        dispatch(getParkingLotOccupation(timeString))

    }, [timeForCalendar]);

    const formatDateTimeYYYMMDD = (startDate) => {
        const dd = String(startDate.getDate()).padStart(2, '0');
        const mm = String(startDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = startDate.getFullYear();
        return yyyy + '-' + mm + '-' + dd
    }

    const changeDay = (daysToChange) => {
      if(!loading) {
        let newDate = new Date(timeForCalendar);
        newDate.setDate(newDate.getDate() + (daysToChange))
        setTimeForCalendar(newDate)
      }
    }

    useEffect(() => {
        setStartDate(new Date())
    }, []);

    useEffect(() => {
        const newCategory = [ "12 am", "12:30 am", "1 am", "1:30 am", "2 am", "2:30 am", "3 am", "3:30 am", "4 am", "4:30 am", "5 am", "5:30 am", "6 am", "6:30 am", "7 am", "7:30 am", "8 am", "8:30 am", "9 am", "9:30 am", "10 am", "10:30 am", "11 am", "11:30 am", "12 pm", "12:30 pm", "1 pm", "1:30 pm", "2 pm", "2:30 pm", "3 pm", "3:30 pm", "4 pm", "4:30 pm", "5 pm", "5:30 pm", "6 pm", "6:30 pm", "7 pm", "7:30 pm", "8 pm", "8:30 pm", "9 pm", "9:30 pm", "10 pm", "10:30 pm", "11 pm", "11:30 pm" ];
        const newData = []

        console.log(parkingLotThroughoutDay)
        parkingLotThroughoutDay.forEach(time => {
          newData.push(parseInt((time.pct * 100).toFixed(1)))
        });

        console.log(newCategory, newData)

        if(newData.length > 0) {
          const newChartObject = JSON.parse(JSON.stringify(options))
          newChartObject.xaxis.categories = newCategory
          newChartObject.yaxis.labels.formatter = (value) => { return value + "%" }
          setOptions(newChartObject)
          const newSeries = JSON.parse(JSON.stringify(series))
          newSeries[0].data = newData
          setSeries(newSeries)
          setLoading(false)
        }

    }, [parkingLotThroughoutDay]);

    return (
        <div style={{height:'100%', display:'flex'}}>

            <div style={{height:'100%', width:'100%'}}>
                <div style={{ height:"35%", display:'flex', justifyContent:'space-around'}}>
                    <TotalSessions totalSessions={totalSessions} totalSessionsPrev={totalSessionsPrev} usersThisDay={usersThisDay} newUsers={newUsers}></TotalSessions>
                    <MedWidget data={{title: "NEW USERS", value: newUsers, prevValue: newUsersPrev }} icon={<PersonFill  color='rgb(26, 36, 56)' height={"100%"} width={"auto"}  style={{display:"flex", }} ></PersonFill>}></MedWidget>
                    <MedWidget data={{title: "AVG DURATION, MIN", value: averageMinDuration, prevValue : averageMinDurationPrev }}  icon={<Clock  color='rgb(26, 36, 56)' height={"100%"} width={"auto"}  style={{display:"flex", }} ></Clock>}></MedWidget>
                    <PeakCongestion parkingLotThroughoutDay={parkingLotThroughoutDay} />
                    <div>
                        <h2 style={{color: 'white',fontWeight: 'bold', fontSize:"1.2vw", marginTop: 10}}>
                            Date Selector
                        </h2><br />
                        <DatePicker disabled={loading} value={timeForCalendar} onChange={setTimeForCalendar}></DatePicker>
                    </div>
                </div>

                <div style={{ height:"300px", paddingLeft:"30px"}}>
                    <h2 style={{color: 'white',fontWeight: 'bold', fontSize:"1.2vw"}}>
                        Occupancy Chart <ArrowLeft onClick={() => changeDay(-1)} style={{cursor: "pointer", marginLeft: 15, marginRight: 5}}></ArrowLeft><ArrowRight style={{cursor: "pointer"}} onClick={() => changeDay(1)}></ArrowRight>
                    </h2>

                    {loading ?
                      <div style={{margin: 15}}>
                        <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
                          <g fill="none" fill-rule="evenodd">
                              <g transform="translate(1 1)" stroke-width="2">
                                  <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                  <path d="M36 18c0-9.94-8.06-18-18-18">
                                      <animateTransform
                                          attributeName="transform"
                                          type="rotate"
                                          from="0 18 18"
                                          to="360 18 18"
                                          dur="1s"
                                          repeatCount="indefinite"/>
                                  </path>
                              </g>
                          </g>
                        </svg>
                      </div>
                    :
                      <div>
                        <Chart
                          options={options}
                          series={series}
                          type="line"
                          height="300px"
                          width="90%"
                        />
                      </div>
                    }


                </div>

                <div className='small_widget_scroll'>
                    <div  style={{ display:'flex',    alignItems: 'center', paddingRight:'40px', marginTop: '20px'}}>
                        <SmallWidget data={{title: "TRIPS STARTED", value: tripsStarted, prevValue: tripsStartedPrev}} icon={<PersonFill color='rgb(26, 36, 56)' height={"30%"} width={"auto"}  style={{display:"flex", }} ></PersonFill>}></SmallWidget>
                        <SmallWidget data={{title: "TOTAL MIN USED", value: totalMinutes, prevValue: totalMinutesPrev}} icon={<Clock color='rgb(26, 36, 56)' height={"30%"} width={"auto"}  style={{display:"flex", }} ></Clock>}></SmallWidget>
                        <SmallWidget data={{title: "TRIPS COMPLETED", value: tripsCompleted, prevValue: tripsCompletedPrev}} icon={<PersonFill color='rgb(26, 36, 56)' height={"30%"} width={"auto"}  style={{display:"flex", }} ></PersonFill>}></SmallWidget>
                        <SmallWidget data={{title: "TIME TO PARK, MIN", value: totalTimeToPark, prevValue: totalTimeToParkPrev}} icon={<PersonFill color='rgb(26, 36, 56)' height={"30%"} width={"auto"}  style={{display:"flex", }} ></PersonFill>}></SmallWidget>
                        <SmallWidget data={{title: "MILES DRIVEN", value: totalMiles, prevValue: totalMilesPrev}} icon={<PersonFill color='rgb(26, 36, 56)' height={"30%"} width={"auto"}  style={{display:"flex", }} ></PersonFill>}></SmallWidget>
                    </div>
                </div>

            </div>

            {/*
              <div style={{height:"100%", width: "23%", backgroundColor: 'rgb(66, 80, 120)', padding:'1%'}}>
                <p style={{color:'white',fontWeight: 'bold', fontSize:"1vw"}}>Lot Frequency</p>
                <div style={{width:"100%", height:"40%", borderRadius:"10px"}}>
                </div>

                <p style={{color:'white',fontWeight: 'bold', fontSize:"1vw"}}>Statistics</p>
                <div style={{width:"100%", backgroundColor:"rgb(39, 53, 102)", height:"50%", borderRadius:"10px", marginTop:15}}>
                </div>
              </div>
            */}


        </div>
    )
}

const mapStateToProps = (state) => ({
    firstAppOpenCount: state.logs.firstAppOpenCount,
    cumulativeTime: state.logs.cumulativeTime,
    parkingLotThroughoutDay: state.logs.parkingLotThroughoutDay,
    totalSessions: state.logs.totalSessions,
    newUsers: state.logs.newUsers,
    totalMinutes: state.logs.totalMinutes,
    usersThisDay : state.logs.usersThisDay,
    averageMinDuration: state.logs.averageMinDuration,
    tripsStarted: state.logs.tripsStarted,
    tripsCompleted: state.logs.tripsCompleted,
    totalMiles: state.logs.totalMiles,
    totalTimeToPark: state.logs.totalTimeToPark,
    totalSessionsPrev: state.logs.totalSessionsPrev,
    averageMinDurationPrev: state.logs.averageMinDurationPrev,
    newUsersPrev: state.logs.newUsersPrev,
    totalMinutesPrev: state.logs.totalMinutesPrev,
    tripsStartedPrev: state.logs.tripsStartedPrev,
    tripsCompletedPrev: state.logs.tripsCompletedPrev,
    totalTimeToParkPrev: state.logs.totalTimeToParkPrev,
    totalMilesPrev: state.logs.totalMilesPrev
});

export default connect(mapStateToProps)(Stat);
