import { useEffect, useState } from "react";
import DatePicker from "react-date-picker"
import { ArrowUp, ArrowDown } from 'react-bootstrap-icons';


export const TotalSessions = ({totalSessions, totalSessionsPrev, usersThisDay, newUsers}) => {
    const [timeForCalendar, setTimeForCalendar] = useState(new Date())

    let valueChange = 0
    if(totalSessions && totalSessionsPrev) {
      valueChange = totalSessions/totalSessionsPrev
      if(valueChange <= 1) {
        valueChange = 1 - valueChange
      }
    }

    return (
        <div style={{background:"rgb(107, 129, 181)" ,width:'20%', paddingTop: "15px", paddingLeft:"25px" , height:'200px', borderRadius:"0px 0px 10px 10px", color:"white"}}>
            <h2 style={{fontSize:'2vw', fontWeight:"bold"}}>{totalSessions !== false ? totalSessions : "----"}</h2>
            <p style={{fontSize:'0.6vw', fontWeight:"800", color:'lightgrey'}}>TOTAL SESSIONS</p>

            {valueChange !== 0 ?
              <div style={{fontSize:'1vw', fontWeight:"bold", color: valueChange >= 1 ? 'limegreen' : 'red',  lineHeight:'normal', height:'40px'}}>
                {valueChange >= 1 ? <ArrowUp></ArrowUp> : <ArrowDown></ArrowDown>}
                {valueChange >= 1 ? <span>+</span> : <span>-</span>}
                {parseInt(valueChange * 100)}%
              </div>
            :
              <div style={{fontSize:'0.8vw', fontWeight:"bold", color: '#ccc',  lineHeight:'normal', height:'40px'}}>
                No change
              </div>
            }
            <h2 style={{fontSize:'1.2vw', fontWeight:"bold"}}>{usersThisDay !== false ? usersThisDay : "----"}</h2>
            <p style={{fontSize:'0.6vw', fontWeight:"800", color:'lightgrey', marginBottom : 10}}>UNIQUE USERS</p>

            <h2 style={{fontSize:'1.2vw', fontWeight:"bold"}}>{newUsers !== false && usersThisDay !== false ? (usersThisDay - newUsers) : "----"}</h2>
            <p style={{fontSize:'0.6vw', fontWeight:"800", color:'lightgrey'}}>RETURNING USERS</p>

        </div>
    )
}
