import Nav from './Nav';

function PublicPage({ setCurrentSection, currentSection, requestAdminAccess }) {
  return (
    <div>
      <h1 className="title-seo">Nine20 | HOME</h1>
      <div id="wrapper">

        <header className="header">
          <div className="header__top">
            <div className="d-flex justify-content-between align-items-center">
              <div className="header__logo">
                <a href="/"><img src="/images/public/Logo@2x.png" alt="920" /></a>
              </div>
              <Nav currentSection={currentSection} requestAdminAccess={requestAdminAccess} />
            </div>

          </div>
          <div className="d-flex justify-content-between align-items-center header__banner">
              <div className="header__banner__content">
                <h2 className="title-regular-43"><span className="gBold">NEXT GENERATION MAPS</span><br />STRAIGHT TO THE SPOT.</h2>
                <p className="text-regular-23 mt-34">Comprehensive, A.I. powered<br /> parking management solution</p>
                <a href="#contact-us" className="js-anchor btn btn-contact mt-34">Contact Us</a>
              </div>
              <div className="header__banner__img">
                <img src="/images/public/banner.png" alt="" />
              </div>
          </div>
        </header>

        <main role="main"  id="homepage">
          <div className="what" id="technology">
            <div className="container">
              <h2 className="title-block cl-white text-center">What is 920?</h2>
              <div className="row">
                <div className="col-lg-4 text-center what__item">
                  <figure className="what__item__img what1">
                    <img src="/images/public/what1.png" alt="" />
                  </figure>
                  <h3 className="what__item__title text-regular-21 gBold cl-white">Parking-Focused Navigation</h3>
                  <p className="what__item__text cl-white">View in real-time and navigate to an open parking spot with our 3D parking lot display</p>
                </div>
                <div className="col-lg-4 text-center what__item">
                  <figure className="what__item__img what2">
                    <img src="/images/public/what2.png" alt="" />
                  </figure>
                  <h3 className="what__item__title text-regular-21 gBold cl-white">Curb & Queue Management</h3>
                  <p className="what__item__text cl-white">Manage city street parking as well as consumer and commercial queues like drive-thrus, curbside pickups, or loading docks</p>
                </div>
                <div className="col-lg-4 text-center what__item">
                  <figure className="what__item__img what3">
                    <img src="/images/public/what3.png" alt="" />
                  </figure>
                  <h3 className="what__item__title text-regular-21 gBold cl-white">Analytics Software</h3>
                  <p className="what__item__text cl-white">Gather valuable statistics on street or lot parking while tracking usage trends</p>
                </div>
              </div>
            </div>
          </div>

          <div className="how text-center" id="how-it-works">
            <div className="container">
              <h2 className="title-block">How it works</h2>
              <figure className="how__img">
                <img src="/images/public/how.png" alt="" className="d-none d-md-block" />
                <img src="/images/public/how_mobile.png" alt="" className="d-block d-md-none" />
              </figure>
            </div>
          </div>

          <div className="solutions text-center" id="solutions">
            <h2 className="title-block cl-white">Solutions</h2>
            <div className="solutions__content d-flex justify-content-between ">
              <div className="solutions__item ">
                <h3 className="solutions__item__title text-regular-31 gSemi cl-white">FOR CONSUMERS</h3>
                <p className="solutions__item__text cl-white">DRIVE-THRUS<br />MALLS<br />UNIVERSITIES<br />AIRPORTS<br />PARKING GARAGES<br />HOSPITALS<br />GAS STATIONS<br />CAR WASHES<br />CITY PARKING<br />ELECTRIC CAR CHARGING<br />STADIUMS</p>
              </div>
              <div className="solutions__item ">
                <h3 className="solutions__item__title text-regular-31 gSemi cl-white">FOR BUSINESSES</h3>
                <p className="solutions__item__text cl-white">FLEET MANAGEMENT<br />TRAFFIC LIGHT CONTROL<br />LOADING DOCKS<br />CITY CURB MANAGEMENT<br />PARKING METERS<br />CONTACTLESS PICKUPS<br />QUEUE MANAGEMENT<br />STREET DELIVERIES<br />RIDE SHARE DROP OFF/ PICK UP</p>
              </div>
            </div>
          </div>

          <div className="what why" id="why">
            <div className="container">
              <h2 className="title-block cl-white text-center">Why 920?</h2>
              <div className="row">
                <div className="col-md-4 text-center what__item">
                  <figure className="what__item__img why-img">
                    <img src="/images/public/why1.png" alt="" />
                  </figure>
                  <p className="what__item__text why-text cl-white">Searching for parking costs drivers their time and fuel, all while increasing emissions</p>
                </div>
                <div className="col-md-4 text-center what__item">
                  <figure className="what__item__img why-img">
                    <img src="/images/public/why2.png" alt="" />
                  </figure>
                  <p className="what__item__text why-text cl-white">Businesses want customers inside their stores rather than in the parking lot</p>
                </div>
                <div className="col-md-4 text-center what__item">
                  <figure className="what__item__img why-img">
                    <img src="/images/public/why3.png" alt="" />
                  </figure>
                  <p className="what__item__text why-text cl-white">Cities want data on how their curbs can be utilized and monetized</p>
                </div>
              </div>
            </div>
          </div>

          <div className="about text-center" id="about">
            <div className="container">
              <h2 className="title-block">About</h2>
              <p className="about-text">In 2017, 3 college students who were fed up with the parking at their university decided that they've finally had enough. For years they were spiking their blood pressure every morning frantically driving around looking for an open parking spot. Thousands of dollars worth of parking tickets were accumulated as times grew more desperate. They were already 20 minutes late to their 9AM class and were left with no choice but to leave their cars in the faculty parking. </p>
              <p className="about-text">The parking problem has plagued their university for decades. Many students before them had tried to solve it but the solutions offered never worked. The issue wasn't a lack of parking, it was knowing exactly WHERE to park. The only working technique most students had was to circle the lots that they knew they had the best chance of finding an open spot in, or show up at the crack of dawn to secure a space. The solutions that exist on the market currently try to help people find a spot by displaying the total number of open spots on signs, or with indicator lights above the spots in garages. The problem with these solutions is that you still need to physically drive over to that lot or garage to check those signs. And there's no guarantee that there will be an open spot once you arrive. </p>
              <p className="about-text">This is where 920 was born. A parking app that shows you in real-time where open spots are, all while avoiding the need to aimlessly drive around. 920 takes advantage of something that almost every parking lot has, light poles, while providing the data to something that almost every driver has, a smartphone. Using cameras, we can cut down on costly installation that traditional parking indicators are subjected to. There are no batteries to replace, no asphalt to trench, and no constant maintenance required. Once our cameras are setup we work day or night, rain or shine, to provide you with reliable information on where an open spot is. </p>
              <p className="about-text">The mission of 920 is to help drivers save time and money searching for parking, all while reducing emissions from vehicles that are zig zagging through lots. The average American spends nearly 17 hours per year looking for parking. We want to cut that down significantly. Accidents can occur when drivers aren't paying attention to their surroundings. When people are looking for an open spot, they are looking back and forth while occasionally glancing ahead. We want to keep drivers focused on the road by taking out the guessing that goes into parking. With 920, you'll know exactly where to go.</p>
            </div>
          </div>

          <div className="team text-center" id="team">
            <div className="container">
              <h2 className="title-block">Meet the Team</h2>
              <div className="d-flex justify-content-center team__list">
                <figure className="team__member">
                  <img src="/images/public/team1.png" alt="" />
                </figure>
                <figure className="team__member">
                  <img src="/images/public/team2.png" alt="" />
                </figure>
                <figure className="team__member">
                  <img src="/images/public/team3.png" alt="" />
                </figure>
                <figure className="team__member">
                  <img src="/images/public/team4.png" alt="" />
                </figure>
                <figure className="team__member">
                  <img src="/images/public/team5.png" alt="" />
                </figure>
                <figure className="team__member">
                  <img src="/images/public/team6.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>

          <div className="news text-center " id="news">
            <div className="container">
              <h2 className="title-block cl-white">News</h2>
              <div className="js-slider-3 news__content">
                <div className="news__item">
                  <div className="news__item__block">
                    <h3 className="text-regular-21 gBold cl-white">Android & iOS App</h3>
                    <p className="cl-e1">We are happy to announce that our app is almost complete for both Android and iOS!</p>
                    <p className="date">05/01/2021</p>
                  </div>
                </div>
                <div className="news__item">
                  <div className="news__item__block">
                    <h3 className="text-regular-21 gBold cl-white">University Pilot Program</h3>
                    <p className="cl-e1">We are pleased to announce that UMBC will be participating in a pilot program for the fall semester of 2021!</p>
                    <p className="date">04/21/2021</p>
                  </div>
                </div>
                <div className="news__item">
                  <div className="news__item__block">
                    <h3 className="text-regular-21 gBold cl-white">New Team Member</h3>
                    <p className="cl-e1">Our new CMO Drew Lynch has joined the 920 team! We are very excited to be working with him!</p>
                    <p className="date">02/25/2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="contact text-center" id="contact-us">
            <div className="container">
              <h2 className="title-block">Interested in investing?</h2>
              <h3 className="subtitle-block">Feel free to contact us!</h3>
              <form className="js-form contact___form" action="index.html" method="post">
                <input type="text" name="name" value="" className="form-control " placeholder="Name" required />
                <input type="email" name="email" value="" className="form-control " placeholder="Email Address" required />
                <input type="text" name="phone" value="" className="form-control " placeholder="Phone Number (optional)" required />
                <textarea name="message" rows="8" cols="80" placeholder="Message" className="form-control"></textarea>
                <button type="submit" name="button" className="btn btn-submit">Submit</button>
              </form>
              <p>920 is actively looking to expand its team! Anyone with questions or inquiries may use the form above.</p>
            </div>
          </div>
          <div className="errorPop"></div>
          <div className="successPop"></div>

        </main>
        <footer className="footer">


        </footer>
      </div>
    </div>
  );
}

export default PublicPage;
