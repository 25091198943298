import { ArrowUp, ArrowDown, PersonFill } from 'react-bootstrap-icons';

export const MedWidget = ({icon, data = {title: 'lorem ipsum', value:false, prevValue : false}}) => {

    let valueChange = 0
    if(data.value && data.prevValue) {
      valueChange = data.value/data.prevValue
      if(valueChange < 1) {
        valueChange = 1 - valueChange
      }
    }

    return (
        <div style={{background:"rgb(51, 64, 92)" ,width:'18%', height:'110px', borderRadius:"0px 0px 8% 8%", color:"white", padding:'0.1% 2% 1% 2%'}}>
            <>
                <div style={{height:"60px"}}>
                    <h2 style={{fontSize:'2vw', lineHeight:'normal', fontWeight:"bold"}}>{data.value !== false ? data.value : "--"}</h2>
                    <div style={{fontSize:'.6vw', fontWeight:"800", color:'lightgrey',  lineHeight:'normal'}}>{data.title}</div>
                </div>

                {valueChange !== 0 ?
                  <div style={{fontSize:'1vw', fontWeight:"bold", color: valueChange >= 1 ? 'limegreen' : 'red',  lineHeight:'normal', height:'20px'}}>
                    {valueChange >= 1 ? <ArrowUp></ArrowUp> : <ArrowDown></ArrowDown>}
                    {valueChange >= 1 ? <span>+</span> : <span>-</span>}
                    {parseInt(valueChange * 100)}%
                  </div>
                :
                  <div style={{fontSize:'0.7vw', fontWeight:"bold", color: '#ccc',  lineHeight:'normal', height:'20px'}}>
                    No change
                  </div>
                }

                <div style={{height:'18px', display:'flex', justifyContent:'flex-end'}}>
                    <div style={{width:"fit-content"}}>
                        {
                            icon
                        }
                    </div>
                </div>
            </>
        </div>
    )
}
