import { SET_ADMIN_LOGOUT, SET_ADMIN_USER } from "../actions/actions_admin_users";

const initialState = {
    token: null,
    user: null
};

const adminUser = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADMIN_USER:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        user : payload.user,
        token : payload.token
      }
    case SET_ADMIN_LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        user : null,
        token : null
      }
    default:
      return state;
  }
};

export default adminUser;
