import { ArrowUp, ArrowDown, PersonFill } from "react-bootstrap-icons"

export const SmallWidget = ({icon, data = {title:"Lorem ipson", value: false, prevValue : false}}) => {

    let valueChange = 0
    if(data.value && data.prevValue) {
      valueChange = data.value/data.prevValue
      if(valueChange < 1) {
        valueChange = 1 - valueChange
      }
    }

    return (
        <div style={{background:"rgb(51, 64, 92)" , height:'80%', borderRadius:"10px", color:"white", marginLeft:'3%', aspectRatio: '2/1', padding:'0.7%', display:"flex"}}>

            <div style={{height:"100%", width: '70%', display:"flex", flexDirection:"column", justifyContent:"center"}}>
                <h2 style={{fontSize:'2vw', lineHeight:'normal', fontWeight:"bold"}}>{data.value !== false ? data.value : "--"}</h2>
                <div style={{fontSize:'.6vw', fontWeight:"800", color:'lightgrey',  lineHeight:'normal'}}>{data.title}</div>
            </div>

            <div style={{height:"100%", width: '32%', display:"flex", flexDirection:"column", justifyContent:'space-between'}}>
                {valueChange !== 0 ?
                  <div style={{fontSize:'0.8vw', fontWeight:"bold", color: valueChange >= 1 ? 'limegreen' : 'red',  lineHeight:'normal', height:'30px'}}>
                    {valueChange >= 1 ? <ArrowUp></ArrowUp> : <ArrowDown></ArrowDown>}
                    {valueChange >= 1 ? <span>+</span> : <span>-</span>}
                    {parseInt(valueChange * 100)}%
                  </div>
                :
                  <div style={{fontSize:'0.6vw', fontWeight:"bold", color: '#ccc',  lineHeight:'normal', height:'30px'}}>
                    No change
                  </div>
                }
                {
                    icon
                }
            </div>

        </div>
    )
}
