import * as React from 'react'
import { useEffect } from 'react';
import { Container, Pagination, Table, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { getUsers, setSortOrder, setSortBy } from '../../actions/actions_users';
import { PencilSquare } from 'react-bootstrap-icons';
import { useState } from 'react';
import { useHistory } from "react-router-dom";


const Users = ({dispatch, users, token, total_count, sortBy, sortOrder}) => {
    const [page, setPage] = useState(1)
    const [sortedUsers, setSortedUsers] = useState(users)
    const [usersForPage, setUsersForPage] = useState([])
    const [pageCount, setPageCount] = useState([1])
    const history = useHistory();

    const columns = [{
        dataField: 'device_id',
        text: 'Device ID'
      }, {
        dataField: 'permit',
        text: 'Permit'
      }, {
        dataField: 'location',
        text: 'Location'
      },
      {
          dataField: 'location_utc',
          text: "Time"
      }
    ];

    useEffect(() => {
      if(users.length > 0) {
        sortTable(sortBy, true)
      }
    }, [users])

    useEffect(()=>{
        if(page && sortedUsers){
            const pageUsers = sortedUsers.slice((page - 1) * 10,10 * page)
            setUsersForPage(pageUsers)
            setPageCount(Array(Math.ceil((sortedUsers.length + 1) / 10)).fill())
        }
    }, [page, sortedUsers])

    const sortTable = (sortTerm, firstLoad) => {
      let newSortOrder = sortOrder
      if(sortBy === sortTerm && !firstLoad) {
        newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
      }
      let newSortedUsers = JSON.parse(JSON.stringify(users))
      newSortedUsers.sort((a, b) => a[sortTerm] > b[sortTerm] ? (newSortOrder === 'asc' ? 1 : -1) : (newSortOrder === 'asc' ? -1 : 1))
      if(sortTerm === 'permit') {
        newSortedUsers.sort((a, b) => {
          if(a.permit && b.permit) {
            return a.permit.description > b.permit.description ? (newSortOrder === 'asc' ? 1 : -1) : (newSortOrder === 'asc' ? -1 : 1)
          } else {
            return a.permit ? (newSortOrder === 'asc' ? 1 : -1) : (newSortOrder === 'asc' ? -1 : 1)
          }
        })
      }
      dispatch(setSortBy(sortTerm))
      dispatch(setSortOrder(newSortOrder))
      setSortedUsers(newSortedUsers)
    }

    const openUser = (userID) => {
        history.push("/admin/user/" + userID);
    }

    return (
        <div className='user_db_container'>
            <div>
                <h1 className='user_db_title'>User Database ({sortedUsers ? sortedUsers.length : false})</h1>
                    <table className="user-list-table">
                        <thead>
                            <tr>
                                <td onClick={() => sortTable('first_name')} className='top-th' width="10%" height="8%"><div>Name</div></td>
                                <td onClick={() => sortTable('email')} className='top-th' width="19%" height="8%"><div>Email</div></td>
                                <td onClick={() => sortTable('permit')} className='top-th' width="6%" height="8%"><div>Permit</div></td>
                                <td onClick={() => sortTable('sessions')} className='top-th' width="6%" height="8%"><div>Sessions</div></td>
                                <td onClick={() => sortTable('last_login_at')} className='top-th' width="16%" height="8%"><div>Last Login</div></td>
                                <td onClick={() => sortTable('created_at')} className='top-th' width="16%" height="8%"><div>Created</div></td>
                                <td className='th-hidden' width="6%" height="8%"></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usersForPage.map((user, i) => {
                                    const lastLoginTIme = Date.parse(user.last_login_at);
                                    const signupTime = Date.parse(user.created_at);
                                    var options = {
                                        year: 'numeric', month: 'numeric', day: 'numeric',
                                        hour12: false
                                    }
                                    return (
                                        <tr key={i}>
                                            <td  height="8%" className='short-desc'> {user.first_name + " " + user.last_name}</td>
                                            <td  height="8%" className='short-desc'>{user.email}</td>
                                            <td height="8%" className='short-desc'><div style={{backgroundColor: user.permit ? user.permit.color : ""}} className='permit_letter'>{user.permit ? user.permit.letter : "NA"}</div></td>
                                            <td height="8%" className='short-desc'>{user.sessions}</td>
                                            <td height="8%" className='short-desc'>{lastLoginTIme ? new Intl.DateTimeFormat('en-US', options).format(lastLoginTIme): null}</td>
                                            <td height="8%" className='short-desc'>{signupTime? new Intl.DateTimeFormat('en-US', options).format(signupTime) : null}</td>
                                            <td height="8%" onClick={() => openUser(user.id)} className='edit_user short-desc'><Button size="sm">Details</Button></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

            </div>

            <Pagination>
                <Pagination.First onClick={() => setPage(1)} />
                <Pagination.Prev onClick={() => page > 1 ? setPage(page - 1) : false}  />
                {pageCount.map((e,i) => <Pagination.Item key={i} onClick={()=> setPage(i+1)} active={page === i+1}>{i + 1}</Pagination.Item>)}
                <Pagination.Next onClick={() => page < pageCount.length? setPage(page + 1) : false} />
                <Pagination.Last onClick={() => setPage(pageCount.length)}/>
            </Pagination>
        </div>
    )
}
const mapStateToProps = (state) => ({
    users: state.users.users,
    token: state.adminUser.token,
    sortBy: state.users.sortBy,
    sortOrder : state.users.sortOrder,
    total_count: state.users.total_count
});

export default connect(mapStateToProps)(Users);
