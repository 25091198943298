import { API_URL } from '../AppConstants';
export const SET_USERS = "SET_USERS";
export const SET_PASSWORD_VALIDATION_STATE = "SET_PASSWORD_VALIDATION_STATE";
export const SET_PASSWORD_RESPONSE = "SET_PASSWORD_RESPONSE";
export const SET_ADMIN_USER = "SET_ADMIN_USER";
export const SET_ADMIN_LOGOUT = "SET_ADMIN_LOGOUT";

export function loginAdmin(email, password) {
  return (dispatch) => {
    // Sends email, password, total currency to start
    return fetch(`${API_URL}/admin_users/login`,{
      method: 'POST',
      body: JSON.stringify({email, password}),
      headers: {
          'Content-Type': 'application/json'
      }
  }).then(resp => resp.json()).then(response => {

      if(response.token){
        dispatch(setAdminUserLoggedIn(response.user, response.token))
      }
    });
  };
}

export function setAdminUserLoggedIn(user, token) {
  return {
    type: SET_ADMIN_USER,
    payload: {user, token},
  };
}

export function setAdminUserLoggedOut() {
  return {
    type: SET_ADMIN_LOGOUT
  };
}