import { API_URL } from '../AppConstants';
export const SET_FIRST_APP_OPEN_COUNT = "SET_FIRST_APP_OPEN_COUNT";
export const SET_CUMULATIVE_TIME = "SET_CUMULATIVE_TIME";
export const SET_PARKING_LOT_OCCUPATION = "SET_PARKING_LOT_OCCUPATION";
export const SET_STATS = "SET_STATS";
export const SET_TRIPS_STARTED = "SET_TRIPS_STARTED";
export const SET_TRIPS_COMPLETED = "SET_TRIPS_COMPLETED";
export const SET_TIME_TO_PARK = "SET_TIME_TO_PARK";
export const SET_MILES_DRIVEN = "SET_MILES_DRIVEN";

export function getFirstAppOpenCount() {
    return (dispatch) => {
    return fetch(`${API_URL}/logs/first_app_open`).then(resp => resp.json()).then(response => {
        dispatch(setFirstAppOpenCount(response))
    });
    };
}

export function getCumulativeTime(date) {
    return (dispatch) => {
        return fetch(`${API_URL}/logs/cum_time_per_day?date=${date}`).then(resp => resp.json()).then(response => {
            dispatch(setCumulativeTime(response))
        });
    };
}

export function getParkingLotOccupation(date) {
    return (dispatch) => {
        return fetch(`${API_URL}/logs/pct_lot_full?date=${date}&location=14`).then(resp => resp.json()).then(response => {
            dispatch(setParkingLotOccupation(response))
        });
    };
}

export function getStatsForDate(date) {
    return (dispatch) => {
      return fetch(`${API_URL}/logs/stats_for_past_week?date=${date}`).then(resp => resp.json()).then(response => {
          console.log(response)
          // dispatch(setFirstAppOpenCount(response))
          dispatch(setStats(response))
      });
    };
}

export function getTripsStarted(date) {
    return (dispatch) => {
      return fetch(`${API_URL}/logs/trips_started?date=${date}`).then(resp => resp.json()).then(response => {
          console.log(response)
          // dispatch(setFirstAppOpenCount(response))
          dispatch(setTripsStarted(response))
      });
    };
}

export function getTripsCompleted(date) {
    return (dispatch) => {
      return fetch(`${API_URL}/logs/trips_completed?date=${date}`).then(resp => resp.json()).then(response => {
          console.log(response)
          // dispatch(setFirstAppOpenCount(response))
          dispatch(setTripsCompleted(response))
      });
    };
}

export function getTimeToPark(date) {
    return (dispatch) => {
      return fetch(`${API_URL}/logs/time_to_park?date=${date}`).then(resp => resp.json()).then(response => {
          console.log(response)
          // dispatch(setFirstAppOpenCount(response))
          dispatch(setTimeToPark(response))
      });
    };
}

export function getMilesDriven(date) {
    return (dispatch) => {
      return fetch(`${API_URL}/logs/miles_driven?date=${date}`).then(resp => resp.json()).then(response => {
          console.log(response)
          // dispatch(setFirstAppOpenCount(response))
          dispatch(setMilesDriven(response))
      });
    };
}

export function setFirstAppOpenCount(count) {
    return {
        type: SET_FIRST_APP_OPEN_COUNT,
        payload: count,
    };
}

export function setCumulativeTime(count) {
    return {
        type: SET_CUMULATIVE_TIME,
        payload: count,
    };
}

export function setParkingLotOccupation(count) {
    return {
        type: SET_PARKING_LOT_OCCUPATION,
        payload: count,
    };
}
export function setStats(stats) {
    return {
        type: SET_STATS,
        payload: stats,
    };
}
export function setTripsStarted(stats) {
    return {
        type: SET_TRIPS_STARTED,
        payload: stats,
    };
}
export function setTripsCompleted(stats) {
    return {
        type: SET_TRIPS_COMPLETED,
        payload: stats,
    };
}
export function setTimeToPark(stats) {
    return {
        type: SET_TIME_TO_PARK,
        payload: stats,
    };
}
export function setMilesDriven(stats) {
    return {
        type: SET_MILES_DRIVEN,
        payload: stats,
    };
}
